import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGOUT,
} from "../actions/types";
import { notification } from "antd";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  loading: true,
  user: null,
  subscription: null,
  userType: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
        subscription: payload.subscription,
      };
    case LOGIN_SUCCESS:
      const { userType } = payload;

      if (userType === "owner") {
        localStorage.setItem("token", payload.token);
        return {
          ...state,
          ...payload,
          isAuthenticated: true,
          loading: false,
        };
      }
      if (userType === "operator") {
        notification.error({
          message: "Xəta",
          description: `Yanlış istifadəçi növü: ${userType}`,
        });
        return {
          ...state,
          isAuthenticated: false,
          loading: false,
        };
      } else {
        notification.error({
          message: "Xəta",
          description: `Yanlış istifadəçi növü: ${userType}`,
        });
        return {
          ...state,
          isAuthenticated: false,
          loading: false,
        };
      }

    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem("token");
      if (window.localStorage) {
        window.localStorage.clear();
        window.sessionStorage.clear();
      }
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
      };
    case AUTH_ERROR:
    default:
      return state;
  }
}
